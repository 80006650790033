<template>
  <img :src="largeLogoImageUrl" :alt="$t('components.logoAltText')" :width="width" :height="height" />
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    width?: string;
    height?: string;
  }>(),
  {
    width: '145',
    height: '32',
  }
);

const dashboardCustomizationStore = useDashboardCustomization();

const largeLogoImageUrl = computed(() => dashboardCustomizationStore.largeLogoImageUrl);
</script>
